var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h2", [_vm._v("接口IotConnectorInOutConverter")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "/**\n * 数据转换器\n *\n * @param <T> 数据转换实体类 {@link ConnectorCoderEntity}\n * @author daiyu\n */"
                  )
                ]),
                _vm._v("\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("interface")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("IotConnectorInOutConverter")]
                ),
                _vm._v("<T> {\n\n\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "/**\n     * 从连接器Connector输入到调度器时执行\n     *\n     * @param connectorCoderMessage\n     * @return\n     */"
                  )
                ]),
                _vm._v("\n    ConnectorMessage "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("decoder")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v("(T connectorCoderMessage)")
                ]),
                _vm._v(";\n\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "/**\n     * 解码后置方法\n     *\n     * @param connectorMessage 消息\n     * @param connectorSession 该设备的Session\n     * @param iotConnector     iotConnector\n     * @return 返回false不继续处理\n     */"
                  )
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("boolean")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("decoderAfter")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v(
                    "(ConnectorMessage connectorMessage, ConnectorSession connectorSession, IotConnector iotConnector)"
                  )
                ]),
                _vm._v(";\n\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "/**\n     * 从调度器输入到Connector时执行\n     *\n     * @param msg\n     * @return\n     */"
                  )
                ]),
                _vm._v("\n    T "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("encoder")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v(
                    "(ConnectorMessage msg, ConnectorSession connectorSession)"
                  )
                ]),
                _vm._v(";\n\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("在decoder中获取原始数据并解析")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v("\n    @Override\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" ConnectorMessage "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("decoder")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v("(TcpConnectorCoderEntity connectorCoderMessage)")
                ]),
                _vm._v(" {\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("try")
                ]),
                _vm._v(" {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("byte")
                ]),
                _vm._v(
                  "[] bytes = connectorCoderMessage.getData();\n            "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("msg")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("String")]
                ),
                _vm._v(
                  "(bytes, StandardCharsets.UTF_8);\n            log.info("
                ),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"执行数据解码:{}"')
                ]),
                _vm._v(", msg);\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" (msg == "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("null")
                ]),
                _vm._v(" || "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('""')
                ]),
                _vm._v(".equals(msg)) {\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("null")
                ]),
                _vm._v(";\n            }\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//开始数据解析")
                ]),
                _vm._v("\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("id")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" msg.substring("),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("12")
                ]),
                _vm._v(");\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("cmd")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" msg.substring("),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("12")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("14")
                ]),
                _vm._v(");\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//包装返回数据")
                ]),
                _vm._v("\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("ConnectorMessage")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("resultMsg")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("ConnectorMessage")]
                ),
                _vm._v("();\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//设置三个必须的参数")
                ]),
                _vm._v(
                  "\n            resultMsg.setSessionId(id);\n            resultMsg.setDeviceCode(id);\n            resultMsg.setProductCode(productCode);\n            JSONObject data="
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("JSONObject")]
                ),
                _vm._v("();\n            data.put("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"id"')
                ]),
                _vm._v(",id);\n            data.put("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"cmd"')
                ]),
                _vm._v(",cmd);\n          \t"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//数据包装")
                ]),
                _vm._v("\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("JSONObject")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("body")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("JSONObject")]
                ),
                _vm._v("();\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//这里根据物联模型包装到不同的属性里面")
                ]),
                _vm._v("\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" ("),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("true")
                ]),
                _vm._v(") {\n                body.put("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"properties"')
                ]),
                _vm._v(", data);\n            } "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("else")
                ]),
                _vm._v(" {\n                body.put("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"events"')
                ]),
                _vm._v(", data);\n            }\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//这里可以设置其他数据向下传递")
                ]),
                _vm._v(
                  "\n            resultMsg.setOtherPayLoad(data);\n            "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//设置最后的负载")
                ]),
                _vm._v(
                  "\n            resultMsg.setPayLoad(body);\n            log.info(JSONObject.toJSONString(resultMsg));\n            "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" resultMsg;\n        } "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("catch")
                ]),
                _vm._v(
                  " (Exception e) {\n            log.error(e.getLocalizedMessage(), e);\n            "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("null")
                ]),
                _vm._v(";\n        }\n    }\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("在decoderAfter中进行业务处理和数据交互")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "注意：当decoderAfter返回false时，该connectorMessage会被丢弃"
            )
          ]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v("    @Override\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("boolean")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("decoderAfter")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v(
                    "(ConnectorMessage connectorMessage, ConnectorSession connectorSession, IotConnector iotConnector)"
                  )
                ]),
                _vm._v(" {\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("JSONObject")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("data")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(
                  " (JSONObject) connectorMessage.getOtherPayLoad();\n        "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//根据不同的指令回复不同的数据")
                ]),
                _vm._v("\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" ("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Q0"')
                ]),
                _vm._v(".equals(data.getString("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"cmd"')
                ]),
                _vm._v("))) {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("id")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" connectorMessage.getDeviceCode();\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("resp")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" id + "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Q0\\r\\n"')
                ]),
                _vm._v(
                  ";\n            iotConnector.connectorInput(connectorSession.getSessionId(), resp.getBytes(StandardCharsets.UTF_8));\n            "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(";\n        } "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("else")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" ("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"03"')
                ]),
                _vm._v(".equals(data.getString("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"cmd"')
                ]),
                _vm._v("))) {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("id")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" connectorMessage.getDeviceCode();\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("resp")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" id + "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Q0\\r\\n"')
                ]),
                _vm._v(
                  ";\n            iotConnector.connectorInput(connectorSession.getSessionId(), resp.getBytes(StandardCharsets.UTF_8));\n            "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("true")
                ]),
                _vm._v(";\n        }\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(";\n    }\n\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("回复数据时的两个方法")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v(
                  "     * 输入数据到connector\n     *\n     * @param msg 数据包装，这个方法会调用encoder\n     */\n    "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("void")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("connectorInput")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v("(ConnectorMessage msg)")
                ]),
                _vm._v(";\n\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "/**\n     * 输入数据到connector，这个方法直接发送字节数据\n     *\n     * @param bytes\n     */"
                  )
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("void")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("connectorInput")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v("(String sessionId, "),
                  _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                    _vm._v("byte")
                  ]),
                  _vm._v("[] bytes)")
                ]),
                _vm._v(";\n")
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7f24f69c", { render: render, staticRenderFns: staticRenderFns })
  }
}