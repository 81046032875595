var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h1", [_vm._v("数据解析")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "平台与设备通过标准Json数据协议进行通信，是一套基于JSON标准定义的用户层协议，具有可读性高的特点，能够直观展示数据交互细节。但在物联网场景中，存在低配置且资源受限的设备，往往不适合直接使用JSON数据格式，而是采用二进制数据格式与平台进行通信。针对该应用场景，平台提供数据解析功能，支持通过编写数据解析脚本实现自定义数据格式（二进制）与标准物模型数据格式之间的转换。"
            )
          ]),
          _vm._v(" "),
          _c("h1", [_vm._v("协议接入流程")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "泛协议接入方案主要采用协议适配独立服务，通过协议适配服务可以快速将原有的二进制协议或者文本协议转换为IOT标准协议。\n详细接入请参考开发手册"
            ),
            _c(
              "a",
              {
                attrs: { href: "https://shudi.yuque.com/kfmqg4/to5q21/ucg4zy" }
              },
              [_vm._v("https://shudi.yuque.com/kfmqg4/to5q21/ucg4zy")]
            ),
            _vm._v("\n详细示例请参考源码中：iot-adapter-demo\n"),
            _c("img", {
              attrs: { src: require("../img/md/extensive.png"), alt: "泛协议" }
            }),
            _vm._v(
              "\n设备与适配服务通讯采用设备原始通讯协议，通过二进制数据或者文本字符串交互，在适配服务种转换为json格式，并采用mqtt与平台进行通信交互，适配服务主要功能是提供数据解析转换能力。"
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("SDK基础")]),
          _vm._v(" "),
          _c("h2", [_vm._v("整体架构")]),
          _vm._v(" "),
          _c("p", [
            _c("img", {
              attrs: { src: require("../img/md/sdk.png"), alt: "整体架构" }
            })
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("数据调度适配器(Adapter)")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "主要负责调度各个Connector的数据输入和输出,维护各连接器的数据流向的映射关系。"
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("数据连接器(Connector)")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "数据连接器是TCP，HTTP，MQTT等数据源的连接器端子，提供输入输出功能，是数据调度器操作的基本单元。"
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("数据转换（Converter）")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "每个连接器必须指定一个Converter进行数据转换，详细参考Converter"
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("其他关键名词")]),
          _vm._v(" "),
          _c("h3", [_vm._v("ConnectorMessage")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("数据转发的基本单位，每次Adapter转发一个ConnectorMessage")
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("ConnectorSession")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "在一对多情况下需要维护Session以数据不回混乱，每个Connector自行维护Session和sessionId\n注意：必须保证sessionId全局唯一"
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("引入相关依赖")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v(
                  "       <dependency>\n            <groupId>com.paramland.iot</groupId>\n            <artifactId>iot-adapter-spring-boot-starter</artifactId>\n        </dependency>\n"
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("编写配置文件")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-yaml" } },
              [
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("iot-adapter:")
                ]),
                _vm._v("\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("enabled:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("true")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("##开启关闭")
                ]),
                _vm._v("\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("businessThreads:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("##业务线程数量")
                ]),
                _vm._v("\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("connectors:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("//多个连接器")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-bullet" } }, [
                  _vm._v("-")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("name:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("mqtt")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("//单个连接器名称")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("connectorType:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("mqtt_client")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("连接器类型")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("clientId:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("server-sitest1")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("//不同连接器类型不同的配置参数")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("brokerUri:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tcp://10.10.2.185:1883")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("username:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("sitest1")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("password:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("abc")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("iotConverterName:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("mqtt")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-bullet" } }, [
                  _vm._v("-")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("name:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tcp_wt")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("connectorType:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tcp_server")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("port:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("8501")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("backlog:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("128")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("delimiter:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"\\r\\n,\\n"')
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("iotConverterName:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("wt_901")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-bullet" } }, [
                  _vm._v("-")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("name:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tcp_jt")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("connectorType:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tcp_server")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("port:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("8502")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("backlog:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("128")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("delimiter:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"~"')
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("iotConverterName:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tk60")
                ]),
                _vm._v("\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("adapters:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("##添加映射关系   连接器名称:多个连接器名称，逗号隔开")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("tcp_wt:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("mqtt")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("tcp_jt:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("mqtt")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("mqtt:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tcp_wt,tcp_jt")
                ]),
                _vm._v("\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "备注：mqtt_client请参看MqttClientConfigProperties\ntcp_server请参考TcpServerConfigProperties\n使用注解标识Converter并实现接口IotConnectorInOutConverter"
            )
          ]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//名称与配置文件中iotConverterName对应")
                ]),
                _vm._v("\n@Converter(name = "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"名称"')
                ]),
                _vm._v(")\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v("\n"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "/**\n * 数据转换器\n *\n * @param <T> 数据转换实体类 {@link ConnectorCoderEntity}\n * @author daiyu\n */"
                  )
                ]),
                _vm._v("\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("interface")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("IotConnectorInOutConverter")]
                ),
                _vm._v("<T> {\n\n\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "/**\n     * 从连接器Connector输入到调度器时执行\n     *\n     * @param connectorCoderMessage 输入消息\n     * @return 返回解码结果\n     */"
                  )
                ]),
                _vm._v("\n    ConnectorMessage "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("decoder")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v("(T connectorCoderMessage)")
                ]),
                _vm._v(";\n\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "/**\n     * 解码后置方法\n     *\n     * @param connectorMessage 消息\n     * @param connectorSession 该设备的Session\n     * @param iotConnector     iotConnector\n     * @return 返回false不继续处理\n     */"
                  )
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("boolean")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("decoderAfter")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v(
                    "(ConnectorMessage connectorMessage, ConnectorSession connectorSession, IotConnector iotConnector)"
                  )
                ]),
                _vm._v(";\n\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "/**\n     * 从调度器输入到Connector时执行\n     *\n     * @param msg 输入消息\n     * @return 返回编码结果\n     */"
                  )
                ]),
                _vm._v("\n    T "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("encoder")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v(
                    "(ConnectorMessage msg, ConnectorSession connectorSession)"
                  )
                ]),
                _vm._v(";\n\n}\n\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("示例")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v("@Slf4j\n"),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("@Converter")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v("(name = "),
                  _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                    _vm._v('"wt_901"')
                  ]),
                  _vm._v(")")
                ]),
                _vm._v("\n@Component\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("class")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("WtTcpIotConnectorInOutConverter")]
                ),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("implements")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("IotConnectorInOutConverter")]
                ),
                _vm._v("<TcpConnectorCoderEntity> {\n   \t"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//提取产品编码")
                ]),
                _vm._v("\n    @Value("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"${iot-adapter.wt_901.productCode}"')
                ]),
                _vm._v(")\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String productCode;\n\n    @Override\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" ConnectorMessage "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("decoder")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v("(TcpConnectorCoderEntity connectorCoderMessage)")
                ]),
                _vm._v(" {\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("try")
                ]),
                _vm._v(" {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//获取tcp上传的数据")
                ]),
                _vm._v("\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("byte")
                ]),
                _vm._v(
                  "[] bytes = connectorCoderMessage.getData();\n            "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("msg")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("String")]
                ),
                _vm._v(
                  "(bytes, StandardCharsets.UTF_8);\n            log.info("
                ),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"执行数据解码:{}"')
                ]),
                _vm._v(", msg);\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" (msg == "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("null")
                ]),
                _vm._v(" || "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('""')
                ]),
                _vm._v(".equals(msg)) {\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("null")
                ]),
                _vm._v(";\n            }\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//开始数据解析")
                ]),
                _vm._v("\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("id")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" msg.substring("),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("12")
                ]),
                _vm._v(");\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("cmd")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" msg.substring("),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("12")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("14")
                ]),
                _vm._v(");\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("ConnectorMessage")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("resultMsg")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("ConnectorMessage")]
                ),
                _vm._v(
                  "();\n            resultMsg.setSessionId(id);\n            resultMsg.setDeviceCode(id);\n            resultMsg.setProductCode(productCode);\n            "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("WTDataBean")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("wtDataBean")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("WTDataBean")]
                ),
                _vm._v("();\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("alarmFlag")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"0"')
                ]),
                _vm._v(";\n            log.info("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"指令:{}"')
                ]),
                _vm._v(", cmd);\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("switch")
                ]),
                _vm._v(" (cmd) {\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("case")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Q0"')
                ]),
                _vm._v(
                  ": {\n                    wtDataBean = WTDataBean.builder()\n                            .cmd(cmd)\n                            .build();\n                    "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("break")
                ]),
                _vm._v(";\n                }\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("case")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"03"')
                ]),
                _vm._v(": {\n                    "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("str")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" msg.substring("),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("14")
                ]),
                _vm._v(
                  ", msg.length());\n                    String[] split = str.split("
                ),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('","')
                ]),
                _vm._v(");\n                    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" (!"),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('""')
                ]),
                _vm._v(".equals(split["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("15")
                ]),
                _vm._v("])) {\n                        alarmFlag = split["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("15")
                ]),
                _vm._v(
                  "];\n                    }\n                    wtDataBean = WTDataBean.builder()\n                            .cmd(cmd)\n                            .accelerationX(split["
                ),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v("])\n                            .accelerationY(split["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v("])\n                            .accelerationZ(split["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2")
                ]),
                _vm._v(
                  "])\n                            .angularVelocityX(split["
                ),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("3")
                ]),
                _vm._v(
                  "])\n                            .angularVelocityY(split["
                ),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("4")
                ]),
                _vm._v(
                  "])\n                            .angularVelocityZ(split["
                ),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("5")
                ]),
                _vm._v("])\n                            .angleX(split["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("6")
                ]),
                _vm._v("])\n                            .angleY(split["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("7")
                ]),
                _vm._v("])\n                            .angleZ(split["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("8")
                ]),
                _vm._v("])\n                            .magneticX(split["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("9")
                ]),
                _vm._v("])\n                            .magneticY(split["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10")
                ]),
                _vm._v("])\n                            .magneticZ(split["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("11")
                ]),
                _vm._v("])\n                            .temperature(split["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("12")
                ]),
                _vm._v(
                  "])\n                            .uploadInterval(split["
                ),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("13")
                ]),
                _vm._v(
                  "])\n                            .batteryVoltage(split["
                ),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("14")
                ]),
                _vm._v(
                  "])\n                            .alarmFlag(alarmFlag)\n                            .csq(split["
                ),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("16")
                ]),
                _vm._v("])\n                            .uploadDate(split["),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("17")
                ]),
                _vm._v(
                  "])\n                            .softwareVersion(split["
                ),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("18")
                ]),
                _vm._v(
                  "])\n                            .build();\n                    "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("break")
                ]),
                _vm._v(";\n                }\n                "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("default")
                ]),
                _vm._v(": {\n                    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("null")
                ]),
                _vm._v(";\n                }\n            }\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//开始数据包装，发送给MQTT")
                ]),
                _vm._v("\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("JSONObject")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("body")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("JSONObject")]
                ),
                _vm._v("();\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" ("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"0"')
                ]),
                _vm._v(".equals(alarmFlag)) {\n                body.put("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"properties"')
                ]),
                _vm._v(", JSONObject.toJSON(wtDataBean));\n            } "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("else")
                ]),
                _vm._v(" {\n                body.put("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"events"')
                ]),
                _vm._v(
                  ", JSONObject.toJSON(wtDataBean));\n            }\n            resultMsg.setOtherPayLoad(wtDataBean);\n            resultMsg.setPayLoad(body);\n            log.info(JSONObject.toJSONString(resultMsg));\n            "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" resultMsg;\n        } "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("catch")
                ]),
                _vm._v(
                  " (Exception e) {\n            log.error(e.getLocalizedMessage(), e);\n            "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("null")
                ]),
                _vm._v(";\n        }\n    }\n\t"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "//返回true，该指令继续执行，返回false，该指令不在执行"
                  )
                ]),
                _vm._v("\n    @Override\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("boolean")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("decoderAfter")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v(
                    "(ConnectorMessage connectorMessage, ConnectorSession connectorSession, IotConnector iotConnector)"
                  )
                ]),
                _vm._v(" {\n       \t"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//获取解析的原始数据")
                ]),
                _vm._v("\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("WTDataBean")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("device")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(
                  " (WTDataBean) connectorMessage.getOtherPayLoad();\n        "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//并且根据解析指令回复给设备")
                ]),
                _vm._v("\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" ("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Q0"')
                ]),
                _vm._v(".equals(device.getCmd())) {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("id")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" connectorMessage.getDeviceCode();\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("resp")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" id + "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Q0\\r\\n"')
                ]),
                _vm._v(
                  ";\n            iotConnector.connectorInput(connectorSession.getSessionId(), resp.getBytes(StandardCharsets.UTF_8));\n            "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(";\n        } "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("else")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" ("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"03"')
                ]),
                _vm._v(".equals(device.getCmd())) {\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("id")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" connectorMessage.getDeviceCode();\n            "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("resp")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" id + "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"Q0\\r\\n"')
                ]),
                _vm._v(
                  ";\n            iotConnector.connectorInput(connectorSession.getSessionId(), resp.getBytes(StandardCharsets.UTF_8));\n            "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("true")
                ]),
                _vm._v(";\n        }\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(";\n    }\n\n\n    @Override\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" TcpConnectorCoderEntity "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("encoder")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v(
                    "(ConnectorMessage msg, ConnectorSession connectorSession)"
                  )
                ]),
                _vm._v(" {\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("null")
                ]),
                _vm._v(";\n    }\n\n\n}\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("package")
                ]),
                _vm._v(" com.paramland.iot.adapter.wt901.dto;\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("import")
                ]),
                _vm._v(" lombok.*;\n\n"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "/**\n * @author Elvis\n * @Date 2021/6/8\n * 维特智能角度测量传感器bean\n */"
                  )
                ]),
                _vm._v(
                  "\n@Data\n@AllArgsConstructor\n@NoArgsConstructor\n@Builder\n@ToString\n"
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("class")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("WTDataBean")]
                ),
                _vm._v(" {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String cmd;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String accelerationX;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String accelerationY;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String accelerationZ;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String angularVelocityX;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String angularVelocityY;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String angularVelocityZ;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String angleX;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String angleY;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String angleZ;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String magneticX;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String magneticY;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String magneticZ;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String temperature;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String uploadInterval;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String batteryVoltage;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String alarmFlag;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String csq;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String uploadDate;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String softwareVersion;\n}\n\n")
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a59b9a7a", { render: render, staticRenderFns: staticRenderFns })
  }
}