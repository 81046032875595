var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header"),
      _c("div", [
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "a-menu",
                {
                  staticStyle: { width: "340px", "min-height": "80vh" },
                  attrs: {
                    "default-selected-keys": ["1"],
                    mode: _vm.mode,
                    theme: _vm.theme,
                  },
                  on: { select: _vm.changeMenu },
                },
                [
                  _vm._l(_vm.menuList, function (item) {
                    return [
                      item.children === false
                        ? _c(
                            "a-menu-item",
                            { key: item.router },
                            [
                              _c("a-icon", { attrs: { type: item.icon } }),
                              _c("span", [_vm._v(_vm._s(item.name))]),
                            ],
                            1
                          )
                        : _c(
                            "a-sub-menu",
                            { key: item.router },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [
                                  _c("a-icon", { attrs: { type: item.icon } }),
                                  _c("span", [_vm._v(_vm._s(item.name))]),
                                ],
                                1
                              ),
                              _vm._l(item.children, function (node) {
                                return _c("a-menu-item", { key: node.router }, [
                                  _vm._v(" " + _vm._s(node.name) + " "),
                                ])
                              }),
                            ],
                            2
                          ),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "0 0 30px 30px",
                    width: "calc(100% - 370px)",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        margin: "30px 0 20px",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.gotoHome },
                        },
                        [_vm._v(" 返回主页 ")]
                      ),
                    ],
                    1
                  ),
                  _c("Base", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "1",
                        expression: "index === '1'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                  _c("SDK", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "2",
                        expression: "index === '2'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                  _c("Start", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "3",
                        expression: "index === '3'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                  _c("Guide", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "4",
                        expression: "index === '4'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                  _c("Converter", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "5",
                        expression: "index === '5'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                  _c("SpringBoot", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "6",
                        expression: "index === '6'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                  _c("Standard", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "7",
                        expression: "index === '7'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                  _c("Extensive", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "8",
                        expression: "index === '8'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                  _c("Gateway", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "9",
                        expression: "index === '9'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                  _c("Handbook", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "10",
                        expression: "index === '10'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                  _c("API", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "11",
                        expression: "index === '11'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                  _c("Question", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "12",
                        expression: "index === '12'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                  _c("EdtionUpdate", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "13",
                        expression: "index === '13'",
                      },
                    ],
                    staticClass: "markdown-body",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }