var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h1", [_vm._v("网关设备接入")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "网关设备做唯一个特殊的设备存在，为其他设备提供提供网络接入能力，提供协议转换能力，提供数据可靠管理能力,提供区域性设备管理能力\n"
            ),
            _c("img", {
              attrs: { src: require("../img/md/gateway.png"), alt: "网关设备" }
            }),
            _vm._v(
              "\n注：在网关设备上报数据到平台前必须解决设备模式的唯一性问题\n网关设备直接连接IOT平台，网关设备与IOT平台通讯使用标准协议接入方式，多个设备订阅多个主题"
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1f3acea4", { render: render, staticRenderFns: staticRenderFns })
  }
}