var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h1", [_vm._v("设备的唯一标识")]),
          _vm._v(" "),
          _c("p", [_vm._v("​")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "IOT平台接入的每一个设备都在平台中都应该具有唯一性，所有不具备唯一标识的特性的模块不能称为设备\n采用"
            ),
            _c("code", { pre: true }, [_vm._v("productCode+deviceCode")]),
            _vm._v("组合方式来标识设备的唯一性\n​")
          ]),
          _vm._v(" "),
          _c("h1", [_vm._v("标准协议接入")]),
          _vm._v(" "),
          _c("p", [_vm._v("​")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("标准协议接入采用MQTT标准协议   文档参考："),
            _c(
              "a",
              {
                attrs: { href: "https://mcxiaoke.gitbooks.io/mqtt-cn/content/" }
              },
              [_vm._v("https://mcxiaoke.gitbooks.io/mqtt-cn/content/")]
            ),
            _vm._v("\n在IOT平台中定义了数据上行和数据下行两个主题\n​")
          ]),
          _vm._v(" "),
          _c("p", [
            _c("img", {
              attrs: { src: require("../img/md/standard.png"), alt: "标准协议" }
            }),
            _vm._v("\n​")
          ]),
          _vm._v(" "),
          _c("ul", [_c("li", [_vm._v("上行主题\n​")])]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [_vm._v("paramland-message/产品编码/设备编码/thing/upload\n")]
            )
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("​")]),
          _vm._v(" "),
          _c("ul", [_c("li", [_vm._v("下行主题\n​")])]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [_vm._v("paramland-message/产品编码/设备编码/thing/down\n")]
            )
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("​")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "设备数据上传需要发不到上行主题中，平台数据下发会发布在下行主题当中，设备端需要主动订阅下行主题数据\n​"
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("MQTT标准接入指令分类")]),
          _vm._v(" "),
          _c("p", [_vm._v("​")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_vm._v("online：设备上线指令")]),
            _vm._v(" "),
            _c("li", [_vm._v("info：业务数据指令")]),
            _vm._v(" "),
            _c("li", [
              _vm._v("offline 设备下线指令"),
              _c("br"),
              _vm._v(
                "\n设备每次断开网络连接后重新上线后都必须先发送online指令，然后发送业务数据，最后发送offline下线指令\n​"
              )
            ])
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("MQTT接入标准报文格式")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("class")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("ParamLandIotDto")]
                ),
                _vm._v(" {\n  \t"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("/**本次上行或者下行消息的唯一标识*/")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String msgId;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v(
                    "/** 通知(上行) 指令(下行) 响应(上下行) 0:无需回复 1:需要回复*/"
                  )
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" Integer msgType;\n  \t"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("/**服务端响应的消息唯一标识*/")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String replayMsgId;\n  \t"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("/**产品唯一编号*/")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String productCode;\n\t"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("/**设备唯一编号*/")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String deviceCode;\n\t"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("/**时间戳*/")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" Long timestamp;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("/**online:上线 offline:下线 info:业务数据*/")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" String command;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("/**上行*/")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" JSONObject properties;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("/**上行*/")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" JSONObject events;\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("/**下行*/")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("private")
                ]),
                _vm._v(" JSONObject services;\n}\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("​")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("properties和events的数据上传必须符合物模型中的定义")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1edd9e4a", { render: render, staticRenderFns: staticRenderFns })
  }
}