var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h2", [_vm._v("引入相关依赖")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v(
                  "       <dependency>\n            <groupId>com.paramland.iot</groupId>\n            <artifactId>iot-adapter-spring-boot-starter</artifactId>\n        </dependency>\n"
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("编写配置文件")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-yaml" } },
              [
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("iot-adapter:")
                ]),
                _vm._v("\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("enabled:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("true")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("##开启关闭")
                ]),
                _vm._v("\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("businessThreads:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("10")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("##业务线程数量")
                ]),
                _vm._v("\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("connectors:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("//多个连接器")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-bullet" } }, [
                  _vm._v("-")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("name:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("mqtt")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("//单个连接器名称")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("connectorType:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("mqtt_client")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("连接器类型")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("clientId:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("server-sitest1")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("//不同连接器类型不同的配置参数")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("brokerUri:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tcp://10.10.2.185:1883")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("username:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("sitest1")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("password:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("abc")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("iotConverterName:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("mqtt")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-bullet" } }, [
                  _vm._v("-")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("name:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tcp_wt")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("connectorType:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tcp_server")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("port:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("8501")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("backlog:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("128")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("delimiter:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"\\r\\n,\\n"')
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("iotConverterName:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("wt_901")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-bullet" } }, [
                  _vm._v("-")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("name:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tcp_jt")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("connectorType:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tcp_server")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("port:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("8502")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("backlog:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("128")
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("delimiter:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"~"')
                ]),
                _vm._v("\n      "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("iotConverterName:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tk60")
                ]),
                _vm._v("\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("adapters:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("##添加映射关系   连接器名称:多个连接器名称，逗号隔开")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("tcp_wt:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("mqtt")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("tcp_jt:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("mqtt")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("mqtt:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("tcp_wt,tcp_jt")
                ]),
                _vm._v("\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("wt_901:")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("productCode:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("PD004")
                ]),
                _vm._v("\n  "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("tk60:")
                ]),
                _vm._v("\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-attr" } }, [
                  _vm._v("productCode:")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v("PD003")
                ]),
                _vm._v("\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "备注：mqtt_client请参看MqttClientConfigProperties\ntcp_server请参考TcpServerConfigProperties\n使用注解标识Converter"
            )
          ]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//名称与配置文件中iotConverterName对应")
                ]),
                _vm._v("\n@Converter(name = "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"名称"')
                ]),
                _vm._v(")\n")
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b674ae4e", { render: render, staticRenderFns: staticRenderFns })
  }
}