var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h2", [_vm._v("整体架构")]),
          _vm._v(" "),
          _c("p", [
            _c("img", {
              attrs: { src: require("../img/md/sdk.png"), alt: "整体架构" }
            })
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("数据调度适配器(Adapter)")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "主要负责调度各个Connector的数据输入和输出,维护各连接器的数据流向的映射关系。"
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("数据连接器(Connector)")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "数据连接器是TCP，HTTP，MQTT等数据源的连接器端子，提供输入输出功能，是数据调度器操作的基本单元。"
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("数据转换（Converter）")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "每个连接器必须指定一个Converter进行数据转换，详细参考Converter"
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("其他关键名词")]),
          _vm._v(" "),
          _c("h3", [_vm._v("ConnectorMessage")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("数据转发的基本单位，每次Adapter转发一个ConnectorMessage")
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("ConnectorSession")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "�在一对多情况下需要维护Session以数据不回混乱，每个Connector自行维护Session和sessionId\n注意：必须保证sessionId全局唯一"
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b04634d0", { render: render, staticRenderFns: staticRenderFns })
  }
}