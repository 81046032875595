var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h2", [_vm._v("普通JAVA项目接入")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v(
                  "    <dependency>\n      <groupId>com.paramland.iot</groupId>\n      <artifactId>iot-adapter-sdk</artifactId>\n       <version>${iot.adapter.version}</version>\n    </dependency>\n"
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("SpringBoot项目接入")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v(
                  "    <dependency>\n       <groupId>com.paramland.iot</groupId>\n       <artifactId>iot-adapter-spring-boot-starter</artifactId>\n       <version>${iot.adapter.version}</version>\n    </dependency>\n"
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("其他依赖")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v(
                  "        <dependency>\n            <groupId>commons-beanutils</groupId>\n            <artifactId>commons-beanutils</artifactId>\n        </dependency>\n        <dependency>\n            <groupId>com.alibaba</groupId>\n            <artifactId>fastjson</artifactId>\n        </dependency>\n"
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("简单示例")]),
          _vm._v(" "),
          _c("p", [_vm._v("启动一个tcp的服务器")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v("@Slf4j\n"),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("class")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("TcpServerDemo")]
                ),
                _vm._v(" {\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("static")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("void")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("main")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v("(String[] args)")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("throws")
                ]),
                _vm._v(" Exception {\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("TcpServerConfig")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("tcpConfig")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("TcpServerConfig")]
                ),
                _vm._v("();\n        tcpConfig.setPort("),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("8080")
                ]),
                _vm._v(");\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("DelimiterBasedFrameFrameDecoderConfig")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("frameDecoderConfig")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("DelimiterBasedFrameFrameDecoderConfig")]
                ),
                _vm._v(
                  "();\n        tcpConfig.setFrameDecoderConfig(frameDecoderConfig);\n\n        tcpConfig.setConnectorSessionDao("
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("DefaultConnectorSessionDao")]
                ),
                _vm._v(
                  "());\n        tcpConfig.setIotConnectorInOutConverter("
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("WtTcpIotConnectorInOutConverter")]
                ),
                _vm._v("());\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("IotConnector")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("iotConnector")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("TcpServerConnector")]
                ),
                _vm._v(
                  "();\n        iotConnector.setConfig(tcpConfig);\n        iotConnector.setExecutorService("
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("ThreadPoolExecutor")]
                ),
                _vm._v("("),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("1")
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("2")
                ]),
                _vm._v(", TimeUnit.SECONDS, "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("LinkedBlockingDeque")]
                ),
                _vm._v("<>()));\n        iotConnector.start();\n    }\n}\n\n")
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-34d5e510", { render: render, staticRenderFns: staticRenderFns })
  }
}