export const list = [
    {
        "name": '基础',
        "router": '1',
        "icon": 'gold',
        "children": false
    },
    {
        "name": '安装部署',
        "router": 'install',
        "icon": 'tool',
        "children": [
            {
                "name": 'SDK基础',
                "router": '2'
            },
            {
                "name": '快速开始',
                "router": '3'
            },
            {
                "name": '开发指南',
                "router": '4'
            },
            {
                "name": 'Converter编写详细介绍',
                "router": '5'
            },
            {
                "name": 'SpringBoot快速接入',
                "router": '6'
            }
        ]
    },
    {
        "name": '设备接入最佳实践',
        "router": 'deployment-unit',
        "icon": 'api',
        "children": [
            {
                "name": '标准协议接入',
                "router": '7'
            },
            {
                "name": '泛协议接入',
                "router": '8'
            },
            {
                "name": '网关设备接入',
                "router": '9'
            }
        ]
    },
    {
        "name": '操作手册',
        "router": '10',
        "icon": 'book',
        "children": false
    },
    {
        "name": 'API接口说明',
        "router": '11',
        "icon": 'file-text',
        "children": false
    },
    {
        "name": '常见问题',
        "router": '12',
        "icon": 'question-circle',
        "children": false
    },
    {
        "name": '版本更新',
        "router": '13',
        "icon": 'reload',
        "children": false
    }
]
