var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h1", [_vm._v("基础")]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("h3", [_vm._v("平台介绍")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "数地云物联网中心（ParamIoT）定位为PaaS服务平台，在数地若干行业应用和真实设备之间搭建高效、稳定、安全的设备接入服务平台：面向设备，适配多种"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "网络环境和常见传输协议，提供各类硬件终端的快速接入方案和设备管理服务；面向应用层，提供丰富的API和数据分发能力以满足各类行业应用系统的开"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "发需求，使项目实施团队可以更加专注于自身应用的开发，而不用将工作重心放在设备接入调试上，从而缩短项目整体交付周期，降低项目研发、运维成本。"
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("核心特性")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "统一设备接入：提供MQTT、UDP、TCP透传、NB-IoT、私有协议标准接入规范，支持直连、网关、云云对接等多种接入方式，支持百万级海量接入，十万级并发；"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "物模型模板：提供灵活的物模型定义及多行业多品类模板，构建标准化数据模型，解决数据碎片化，简化设备与应用开发；"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "能力网关：支撑服务一体化，聚合LBS定位、边缘计算、规则引擎等物联网能力，提供多种行业应用API；"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "运维监控：提供设备生命周期管理、业务监控、链路监控等能力，实时反映感知设备接入情况，实现平台业务自动化运维和统一告警管理；"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "边缘计算：提供安全可靠、低延时、低成本、易扩展、弱依赖的本地计算服务，打造“云-边-端-业” 一体化解决方案；"
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("技术栈")]),
          _vm._v(" "),
          _c("p", [_vm._v("基础框架：springboot")]),
          _vm._v(" "),
          _c("p", [_vm._v("网关：apisix 请求转发")]),
          _vm._v(" "),
          _c("p", [_vm._v("网络通信框架：netty")]),
          _vm._v(" "),
          _c("p", [_vm._v("时序数据存储：influxdb timescaledb elasticsearch")]),
          _vm._v(" "),
          _c("p", [_vm._v("分布式缓存：redis")]),
          _vm._v(" "),
          _c("p", [_vm._v("业务功能数据管理：mysql")]),
          _vm._v(" "),
          _c("p", [_vm._v("mqtt服务器：emqx")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("数地基础业务框架（orm框架、消息发送、认证授权等公共模块）")
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("服务架构")]),
          _vm._v(" "),
          _c("p", [
            _c("img", {
              attrs: {
                src: require("../img/md/base1.png"),
                alt: "服务器组件构成图"
              }
            })
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "物联网平台：核心业务功能组件，提供设备管理、数据展示、运维监控、应用开发支持以及增值服务能力；"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Rabbit消息队列：消息中间件，用于物联网中心与其他业务模块(如规则引擎等)异步通信；"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "规则引擎：基于sql和自定义表达式的自定义规则处理服务，可自定规则对iot数据进行告警或者联动处理；"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "数据库：mysql数据库作为结果存储方案，提供业务数据的存储；influxD作为过程存储方案，提供设备数据、告警、事件等内容"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "接入适配器：根据物联网中心提供的sdk ,用户仅需完成设备自定协议到平台标准物模型的转换器编写以及配置相关接入信息,即可便捷完成设备接入物联网平台；"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "emqx：mqtt协议代理服务器，用于直连设备与物联网中心，服务实例与物联网中心基于的mqtt协议的通信；"
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("设备接入流程")]),
          _vm._v(" "),
          _c("p", [_vm._v("标准协议接入")]),
          _vm._v(" "),
          _c("p", [
            _c("img", {
              attrs: {
                src: require("../img/md/access1.jpg"),
                alt: "标准协议接入"
              }
            })
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("泛协议接入")]),
          _vm._v(" "),
          _c("p", [
            _c("img", {
              attrs: {
                src: require("../img/md/access2.jpg"),
                alt: "泛协议接入"
              }
            })
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("名词术语")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("术语")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("解释")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("产品")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "具有同样相同属性的设备集合，物联网平台(ParamIoT)以产品作为设备接入管理的基础对象，每个产品拥有独立的物模型json、产品ID以及产品tKEY"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("设备")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "平台进行资源分配的最小单位，作为真实设备在平台的映射，终端数据需要经过由设备ID，设备Code组成的唯一参数组合鉴权，才能正确绑定到设备上"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("产品Code(ProductCode)")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "产品在物联网平台的唯一标识码，也是设备登录鉴权参数之一"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("产品KEY(ProductKEY)")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("产品的特征标志符，设备自动注册的鉴权参数之一")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("物模型")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "从属性、服务和事件三个维度，分别描述了产品是什么、能做什么、可以对外提供哪些信息；物模型是有一种产品功能定义方式，也可以简单理解为产品的数据协议内容"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("设备ID")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "设备在物联网平台的唯一标识码，也是设备登录鉴权参数之一"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("设备Code")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "由硬件厂家自定义的设备唯一出厂序列号，创建/注册设备时作为设备参数，在产品内唯一，也是设备登录鉴权参数之一"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("标准协议接入")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "物联网平台提供基于MQTT和CoaP的标准接入协议，规定了设备接入的传输层和应用层规范，并提供大量产品物模型供硬件厂家选择使用；标准协议接入的产品将使用物联网平台公用协议端口进行通信"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("泛协议接入")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "无法直接按照标准协议接入平台的产品，可以采用泛协议接入方案，泛协议接入服务提供了用户自定义协议设备接入平台的能力，提供设备与平台的双向通信能力；通过创建泛协议接入实例的方式，调用独立端口进行通信"
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("更新记录")]),
          _vm._v(" "),
          _c("ol", [_c("li", [_vm._v("2021.08.17  创建开发文档")])])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bdf4dc72", { render: render, staticRenderFns: staticRenderFns })
  }
}