<template>
    <div>
        <Header />
        <div>
            <div class="content">
                <div style="display: flex">
                    <a-menu
                        style="width: 340px; min-height: 80vh"
                        :default-selected-keys="['1']"
                        :mode="mode"
                        :theme="theme"
                        @select="changeMenu">
                        <template v-for="item in menuList">
                            <a-menu-item v-if="item.children === false" :key="item.router">
                                <a-icon :type="item.icon" />
                                <span>{{ item.name }}</span>
                            </a-menu-item>
                            <a-sub-menu v-else :key="item.router">
                                <span slot="title"><a-icon :type="item.icon" /><span>{{ item.name }}</span></span>
                                <a-menu-item v-for="node in item.children" :key="node.router">
                                    {{ node.name }}
                                </a-menu-item>
                            </a-sub-menu>
                        </template>
                    </a-menu>
                    <div style="margin: 0 0 30px 30px; width: calc(100% - 370px)">
                        <p style="margin:30px 0 20px; text-align: right">
                            <a-button type="primary" @click="gotoHome">
                                返回主页
                            </a-button>
                        </p>
                        <Base v-show="index === '1'" class="markdown-body" />
                        <SDK v-show="index === '2'" class="markdown-body" />
                        <Start v-show="index === '3'" class="markdown-body" />
                        <Guide v-show="index === '4'" class="markdown-body" />
                        <Converter v-show="index === '5'" class="markdown-body" />
                        <SpringBoot v-show="index === '6'" class="markdown-body" />
                        <Standard v-show="index === '7'" class="markdown-body" />
                        <Extensive v-show="index === '8'" class="markdown-body" />
                        <Gateway v-show="index === '9'" class="markdown-body" />
                        <Handbook v-show="index === '10'" class="markdown-body" />
                        <API v-show="index === '11'" class="markdown-body" />
                        <Question v-show="index === '12'" class="markdown-body" />
                        <EdtionUpdate v-show="index === '13'" class="markdown-body" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/Header"
import { list } from '@/config/document'
import Base from '@/assets/md/base.md'
import Handbook from '@/assets/md/handbook.md'
import SDK from '@/assets/md/SDK.md'
import Start from '@/assets/md/start.md'
import Guide from '@/assets/md/guide.md'
import Converter from '@/assets/md/Converter.md'
import SpringBoot from '@/assets/md/SpringBoot.md'
import Standard from '@/assets/md/standard.md'
import Extensive from '@/assets/md/extensive.md'
import Gateway from '@/assets/md/gateway.md'
import Question from '@/assets/md/question.md'
import API from '@/assets/md/API.md'
import EdtionUpdate from '@/assets/md/edtionUpdate.md'
export default {
    name: "Index",
    components: {
        Header,
        Base,
        Handbook,
        SDK,
        Start,
        Guide,
        Converter,
        SpringBoot,
        Standard,
        Extensive,
        Gateway,
        Question,
        API,
        EdtionUpdate
    },
    data() {
        return {
            mode: 'inline',
            theme: 'light',
            menuList: list,
            index: '1'
        }
    },
    methods: {
        gotoHome() {
            this.$router.push('/home')
        },
        changeMenu(val) {
            this.index = val.key
        }
    }
}
</script>

<style lang="scss" scoped>
    .content {
        margin: 0 80px 30px 0;
        img {
            width: 60%;
        }
    }
</style>
