var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h1", [_vm._v("版本更新记录")]),
          _vm._v(" "),
          _c("h3", [_vm._v("V1.0 beta版发布")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "2021.09.22   举行了内部发布会，正式发布数地云物联网管理中心beta V1.0版本；"
            )
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("版本核心功能包括：")]),
          _vm._v(" "),
          _c("p", [_vm._v("设备管理：产品管理、设备管理")]),
          _vm._v(" "),
          _c("p", [_vm._v("设备接入：标准接入、泛协议接入")]),
          _vm._v(" "),
          _c("p", [_vm._v("运维监控：告警中心")]),
          _vm._v(" "),
          _c("p", [_vm._v("系统配置：开发文档等")])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6b6e8adc", { render: render, staticRenderFns: staticRenderFns })
  }
}