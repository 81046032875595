var disposed = false
/* script */
var __vue_script__ = null
/* template */
import {render as __vue_render__, staticRenderFns as __vue_static_render_fns__} from "!!../../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-cfee9f94\",\"hasScoped\":false,\"optionsId\":\"0\",\"buble\":{\"transforms\":{}}}!../../../node_modules/vue-loader/lib/selector?type=template&index=0!../../../node_modules/vue-markdown-loader/lib/markdown-compiler.js??ref--14-1!./guide.md"
/* template functional */
var __vue_template_functional__ = false
/* styles */
var __vue_styles__ = null
/* scopeId */
var __vue_scopeId__ = null
/* moduleIdentifier (server only) */
var __vue_module_identifier__ = null
import normalizeComponent from "!../../../node_modules/vue-loader/lib/runtime/component-normalizer"
var Component = normalizeComponent(
  __vue_script__,
  __vue_render__,
  __vue_static_render_fns__,
  __vue_template_functional__,
  __vue_styles__,
  __vue_scopeId__,
  __vue_module_identifier__
)
Component.options.__file = "src/assets/md/guide.md"

/* hot reload */
if (module.hot) {(function () {
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), false)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-cfee9f94", Component.options)
  } else {
    hotAPI.reload("data-v-cfee9f94", Component.options)
  }
  module.hot.dispose(function (data) {
    disposed = true
  })
})()}

export default Component.exports
