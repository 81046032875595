var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h2", [_vm._v("判断是否器是否启动成功？")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("springboot项目启动成功后会提示IOTAdapter自动注册\n"),
            _c("img", {
              attrs: { src: require("../img/md/Q1.png"), alt: "自动注册" }
            })
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("MQTT连接不成功？")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("检查clientId,username,password字段是否填写正确\n"),
            _c("img", {
              attrs: { src: require("../img/md/Q2.png"), alt: "检查字段" }
            })
          ]),
          _vm._v(" "),
          _c("h2", [
            _vm._v("在IotConnectorInOutConverter中如何给设备回复数据")
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("直接回复")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v(
                  "iotConnector.connectorInput(connectorSession.getSessionId(), resp.getBytes(StandardCharsets.UTF_8));\n"
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("通过encoder回复")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [_vm._v("iotConnector.connectorInput(response);\n")]
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("如何使用工具类")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("工具类位于"),
            _c("code", { pre: true }, [
              _vm._v("com.paramland.iot.adapter.core.utils")
            ]),
            _vm._v("，提供asc码，格雷码，16进制数据操作转换等工具类")
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("TCP客户端连接上了适配器但是发送数据没有被处理")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("检查客户端发送报文的结束符是否与配置的结束符号一致\n"),
            _c("img", {
              attrs: { src: require("../img/md/Q3.png"), alt: "检查客户端" }
            })
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("TCP客户端连接适配服务失败")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "1.检查适配服务是否启动成功\n2.检查适配服务所在服务器防火墙是否允许网络连接到指定端口"
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("TCP客户端连接后自动断开")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "适配服务会定期检查不活跃的连接进行清理，以保证服务端资源不被浪费"
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6f6d9712", { render: render, staticRenderFns: staticRenderFns })
  }
}