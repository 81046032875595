var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h1", [_vm._v("操作手册")]),
          _vm._v(" "),
          _c("h2", [_vm._v("产品管理")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "从导航栏菜单“设备管理”-“产品列表”选项，可以进入产品管理页面"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("img", {
              attrs: {
                src: require("../img/md/handbook1.png"),
                alt: "产品管理"
              }
            })
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "添加产品需要必须输入产品名称、选择产品类型、选择节点类型以及联网方式；"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "产品类型：平台提供了多款已经接入完成的标准化物联网产品，选择标准产品意味着无需手动配置产品物模型，直接使用平台已经适配完成的物模型；或者在标准物模型基础上进行微量修改，可以大大节省用户的设备接入成本。如果用户要接入的设备不是平台已经接入过的产品类型，可以选择”其他类别“，然后手动配置完整的物模型文件。"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "节点类型：分为直连设备、子设备、网关设备三类；节点类型会影响后续网关设备与子设备的绑定操作，也会影响接入协议的选择；选择保存以后不可更改。"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "接入协议：对于直连设备，平台提供五种接入协议（MQTT/CoaP/LwM2M/HTTP/泛连接）进行接入，注意如果产品类别选择了平台标准产品，则系统会自动选择接入协议；如果选择了泛连接协议，则以后必须创建相应的泛连接实例才可以接入数据。"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("img", {
              attrs: {
                src: require("../img/md/handbook2.png"),
                alt: "接入协议"
              }
            })
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "通过产品列表的详情按钮，可以进入产品信息（详情）页面，产品详情页面可以查看产品Code，产品key以及该产品下包含的所有设备列表。"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "产品Code：系统随机生成的10位字符串（数字和字母），用来作为该产品在平台的唯一标志。"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "产品Key：系统随机生成的32位字符串（数字和字母），作为接入开发人员对产品进行增删改查操作的鉴权标识。"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("img", {
              attrs: {
                src: require("../img/md/handbook3.png"),
                alt: "产品详情"
              }
            })
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "通过产品详情页面的物模型管理菜单，可以对产品进行物模型配置。"
            )
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("系统功能点：")]),
          _vm._v(" "),
          _c("p", [_vm._v("自定义功能点：")]),
          _vm._v(" "),
          _c("p", [
            _c("img", {
              attrs: {
                src: require("../img/md/handbook4.png"),
                alt: "自定义功能点"
              }
            })
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("设备管理")]),
          _vm._v(" "),
          _c("h2", [_vm._v("创建泛连接实例")])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e02f6c40", { render: render, staticRenderFns: staticRenderFns })
  }
}