var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h2", [_vm._v("一.配置连接器")]),
          _vm._v(" "),
          _c("h3", [_vm._v("MQTT连接器配置")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v("        "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//MQTT客户端连接")
                ]),
                _vm._v("\n        MqttClientConfig mqttClientConfig="),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("MqttClientConfig")]
                ),
                _vm._v("();\n        mqttClientConfig.setClientId("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"clientID"')
                ]),
                _vm._v(");\n        mqttClientConfig.setUsername("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"用户名"')
                ]),
                _vm._v(");\n        mqttClientConfig.setPassword("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"密码"')
                ]),
                _vm._v(");\n        mqttClientConfig.setBrokerUri("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"tcp://10.10.2.185:1883"')
                ]),
                _vm._v(
                  ");\n        MqttClientIotConnector mqttClientIotConnector="
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("MqttClientIotConnector")]
                ),
                _vm._v(
                  "();\n        mqttClientIotConnector.setConfig(mqttClientConfig);\n"
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("TCP服务器配置")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v("    \t "),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//Netty服务端")
                ]),
                _vm._v("\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("TcpServerConfig")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("tcpConfig")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("TcpServerConfig")]
                ),
                _vm._v("();\n        tcpConfig.setPort("),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("8080")
                ]),
                _vm._v(");\n        tcpConfig.setFrameDecoderConfig("),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("DelimiterBasedFrameFrameDecoderConfig")]
                ),
                _vm._v("("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"\\r\\n"')
                ]),
                _vm._v(","),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"\\n"')
                ]),
                _vm._v("));\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("TcpServerConnector")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("tcpServerConnector")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("TcpServerConnector")]
                ),
                _vm._v(
                  "();\n        tcpServerConnector.setConfig(tcpConfig);\n"
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("二.编写相关的转换器")]),
          _vm._v(" "),
          _c("h3", [_vm._v("TCP转换器")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("class")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("WtTcpIotConnectorInOutConverter")]
                ),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("implements")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("IotConnectorInOutConverter")]
                ),
                _vm._v("<TcpConnectorCoderEntity> {\n    @Override\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" ConnectorMessage "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("decoder")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v("(TcpConnectorCoderEntity connectorCoderMessage)")
                ]),
                _vm._v(" {\n      \t"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//这里进行数据解析转换为ConnectorMessage")
                ]),
                _vm._v("\n        retrun "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("null")
                ]),
                _vm._v(";\n    }\n\n    @Override\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("boolean")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("decoderAfter")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v(
                    "(ConnectorMessage connectorMessage, ConnectorSession connectorSession, IotConnector iotConnector)"
                  )
                ]),
                _vm._v(" {\n      \t"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//这里进行设备认证，数据回复等业务操作")
                ]),
                _vm._v("\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("false")
                ]),
                _vm._v(";\n    }\n\n\n    @Override\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" TcpConnectorCoderEntity "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("encoder")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v(
                    "(ConnectorMessage msg, ConnectorSession connectorSession)"
                  )
                ]),
                _vm._v(" {\n       \t"),
                _c("span", { pre: true, attrs: { class: "hljs-comment" } }, [
                  _vm._v("//这里进行数据编码操作")
                ]),
                _vm._v("\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("null")
                ]),
                _vm._v(";\n    }\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("MQTT转换器（与上方操作一致）")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("class")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("MqttIotConnectorInOutConverter")]
                ),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("implements")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("IotConnectorInOutConverter")]
                ),
                _vm._v("<MqttConnectorCoderEntity> {\n\n\n    @Override\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" ConnectorMessage "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("decoder")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v("(MqttConnectorCoderEntity connectorCoderMessage)")
                ]),
                _vm._v(" {\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("ConnectorMessage")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("connectorMessage")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("ConnectorMessage")]
                ),
                _vm._v("();\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("byte")
                ]),
                _vm._v("[] bytes = connectorCoderMessage.getData();\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("String")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("result")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("String")]
                ),
                _vm._v("(bytes);\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("ParamLandIotDto")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("data")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(
                  " JSONObject.parseObject(result, ParamLandIotDto.class);\n        connectorMessage.setDeviceCode(data.getDeviceCode());\n        connectorMessage.setSessionId(data.getDeviceCode());\n        connectorMessage.setCommand(ConnectorMessageConst.MSG_INFO);\n        connectorMessage.setPayLoad(data.getServices());\n        log.info("
                ),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"MQTT解码:{}"')
                ]),
                _vm._v(", result);\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" connectorMessage;\n    }\n\n    @Override\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("boolean")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("decoderAfter")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v(
                    "(ConnectorMessage connectorMessage, ConnectorSession connectorSession, IotConnector iotConnector)"
                  )
                ]),
                _vm._v(" {\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("true")
                ]),
                _vm._v(";\n    }\n\n\n    @Override\n    "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("public")
                ]),
                _vm._v(" MqttConnectorCoderEntity "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title function_" } },
                  [_vm._v("encoder")]
                ),
                _c("span", { pre: true, attrs: { class: "hljs-params" } }, [
                  _vm._v("(ConnectorMessage msg, ConnectorSession session)")
                ]),
                _vm._v(" {\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("MqttConnectorCoderEntity")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("mqttConnectorCoderEntity")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("MqttConnectorCoderEntity")]
                ),
                _vm._v("();\n        log.info("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"MQTT数据编码"')
                ]),
                _vm._v(");\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("ParamLandIotDto")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("paramLandIotDto")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("ParamLandIotDto")]
                ),
                _vm._v(
                  "();\n        paramLandIotDto.setDeviceCode(msg.getDeviceCode());\n        paramLandIotDto.setMsgId(UUID.randomUUID().toString().replace("
                ),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"-"')
                ]),
                _vm._v(", "),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('""')
                ]),
                _vm._v("));\n        paramLandIotDto.setMsgType("),
                _c("span", { pre: true, attrs: { class: "hljs-number" } }, [
                  _vm._v("0")
                ]),
                _vm._v(");\n        "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("JSONObject")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("body")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(
                  " (JSONObject) JSONObject.toJSON(msg.getPayLoad());\n        "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("if")
                ]),
                _vm._v(" (body != "),
                _c("span", { pre: true, attrs: { class: "hljs-literal" } }, [
                  _vm._v("null")
                ]),
                _vm._v(
                  ") {\n            paramLandIotDto.setProperties(body.getJSONObject("
                ),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"properties"')
                ]),
                _vm._v(
                  "));\n            paramLandIotDto.setEvents(body.getJSONObject("
                ),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"events"')
                ]),
                _vm._v(
                  "));\n        }\n        paramLandIotDto.setCommand(msg.getCommand());\n        paramLandIotDto.setProductCode(msg.getProductCode());\n\n        log.info(JSONObject.toJSONString(paramLandIotDto));\n        mqttConnectorCoderEntity.setData(JSONObject.toJSONString(paramLandIotDto).getBytes(StandardCharsets.UTF_8));\n\n        "
                ),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("return")
                ]),
                _vm._v(" mqttConnectorCoderEntity;\n    }\n}\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("三.给Connector设置Converter")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v("mqttClientConfig.setIotConnectorInOutConverter("),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("MqttIotConnectorInOutConverter")]
                ),
                _vm._v("());\ntcpConfig.setIotConnectorInOutConverter("),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("WtTcpIotConnectorInOutConverter")]
                ),
                _vm._v("());\n\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("四.统一注册到数据调度器")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-type" } }, [
                  _vm._v("DataHandleAdapter")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-variable" } }, [
                  _vm._v("dataHandleAdapter")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-operator" } }, [
                  _vm._v("=")
                ]),
                _vm._v(" "),
                _c("span", { pre: true, attrs: { class: "hljs-keyword" } }, [
                  _vm._v("new")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { pre: true, attrs: { class: "hljs-title class_" } },
                  [_vm._v("DefaultDataHandleAdapter")]
                ),
                _vm._v("();\n dataHandleAdapter.registerIotConnector("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"iot"')
                ]),
                _vm._v(
                  ", mqttClientIotConnector);\n dataHandleAdapter.registerIotConnector("
                ),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"tcp"')
                ]),
                _vm._v(", tcpServerConnector);\n")
              ]
            )
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("五.添加映射关系并启动")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c(
              "code",
              { pre: true, attrs: { "v-pre": "", class: "language-java" } },
              [
                _vm._v("dataHandleAdapter.registerSubscriber("),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"iot"')
                ]),
                _vm._v(
                  ", tcpServerConnector);\ndataHandleAdapter.registerSubscriber("
                ),
                _c("span", { pre: true, attrs: { class: "hljs-string" } }, [
                  _vm._v('"tcp"')
                ]),
                _vm._v(
                  ", mqttClientIotConnector);\ndataHandleAdapter.start();\n"
                )
              ]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cfee9f94", { render: render, staticRenderFns: staticRenderFns })
  }
}