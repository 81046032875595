var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("html", [
        _c("head"),
        _c("body", [
          _c("h1", [_vm._v("物联网中心开放接口")]),
          _vm._v(" "),
          _c("p", [_vm._v("修订记录")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("日期")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("描述")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("版本号")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("2021.09.10")]),
                _vm._v(" "),
                _c("td", [_vm._v("创建文档")]),
                _vm._v(" "),
                _c("td", [_vm._v("V1.0.0")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h1", [_vm._v("1 文档概述")]),
          _vm._v(" "),
          _c("h2", [_vm._v("1.1 文档目的")]),
          _vm._v(" "),
          _c("p", [_vm._v("定义接口规范及参数")]),
          _vm._v(" "),
          _c("h2", [_vm._v("1.2 文档范围")]),
          _vm._v(" "),
          _c("p", [_vm._v("内部资料，供研发技术人员参照对接平台接口")]),
          _vm._v(" "),
          _c("h2", [_vm._v("1.3 阅读对象")]),
          _vm._v(" "),
          _c("p", [_vm._v("开发人员、测试人员、系统运维。")]),
          _vm._v(" "),
          _c("h2", [_vm._v("1.4 使用注意")]),
          _vm._v(" "),
          _c("p", [_vm._v("请先通读全文，如遇问题无法解决请联系。")]),
          _vm._v(" "),
          _c("h1", [_vm._v("2 约定和流程")]),
          _vm._v(" "),
          _c("h2", [_vm._v("2.1 约定")]),
          _vm._v(" "),
          _c("h2", [_vm._v("2.2 流程（必读）")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "数地物联网平台定位为PaaS服务，即在物联网应用和真实设备之间搭建高效、稳定、安全的应用平台：面向设备，适配多种网络环境和常见传输协议，提供各类硬件终端的快速接入方案和设备管理服务；面向应用层，提供丰富的API和数据分发能力以满足各类行业应用系统的开发需求，使物联网企业可以更加专注于自身应用的开发，而不用将工作重心放在设备接入层的环境搭建上，从而缩短物联网系统的形成周期，降低企业研发、运营和运维成本。"
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "使用开放接口大致流程如下：\n1.首先登录物联网中心管理平台,在左侧菜单栏选择“设备管理-产品列表”页面,点击添加产品,填写添加产品表单信息完成新产品的创建。\n2.在“我的产品”列表点击详情按钮可以查看产品详情,其中产品Code和产品key 是作为开放接口认证信息。\n3.调用平台开放接口时需要在请求头(header)里面带上前一步获取到的两个参数作为认证信息，具体参数对应关系如下：\nproductCode=产品Code\napiKey=产品Key"
            )
          ]),
          _vm._v(" "),
          _c("h1", [_vm._v("3 其他")]),
          _vm._v(" "),
          _c("h2", [_vm._v("3.1 发送报文约束")]),
          _vm._v(" "),
          _c("p", [_vm._v("参数值使用UTF-8进行URL编码")]),
          _vm._v(" "),
          _c("h2", [_vm._v("3.2 \t返回报文约束")]),
          _vm._v(" "),
          _c("p", [_vm._v("返回报文采用JSON格式的字符串。")]),
          _vm._v(" "),
          _c("h1", [_vm._v("4 功能接口")]),
          _vm._v(" "),
          _c("h2", [_vm._v("4.1 公共参数")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.1.1 说明")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "访问所有接口务必在请求头上带上对应的认证信息productCode和apiKey，认证后对该productCode对应的产品以及所属数据的所有操作视为拥有者的操作，请务必保管好对应的apikey，如有泄露可到数地物联网管理平台进行重置操作。"
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.1.2 参数列表")]),
          _vm._v(" "),
          _c("p", [_vm._v("请求参数 header：")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("是否必填")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("productCode")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("产品code")]),
                _vm._v(" "),
                _c("td", [_vm._v("必填")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("apiKey")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("产品key")]),
                _vm._v(" "),
                _c("td", [_vm._v("必填")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("4.2  获取设备最新上报数据")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.2.1 业务功能")]),
          _vm._v(" "),
          _c("p", [_vm._v("获取指定设备最新上报的一条数据。")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.2.2  接口地址")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "请求方式：GET\n地址：{host} /api/iotdata/latest/{type}/{deviceCode}"
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.2.3 接口参数")]),
          _vm._v(" "),
          _c("p", [_vm._v("请求参数param:")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("是否必填")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("type")]),
                _vm._v(" "),
                _c("td", [_vm._v("int")]),
                _vm._v(" "),
                _c("td", [_vm._v("查询类型,1:查询属性 2:查询动作 3:查询服务")]),
                _vm._v(" "),
                _c("td", [_vm._v("是")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("deviceCode")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("需要查询的设备code")]),
                _vm._v(" "),
                _c("td", [_vm._v("是")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.2.4 返回参数")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("code")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("状态200为成功")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("message")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("提示消息")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("success")]),
                _vm._v(" "),
                _c("td", [_vm._v("boolean")]),
                _vm._v(" "),
                _c("td", [_vm._v("是否成功")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("data")]),
                _vm._v(" "),
                _c("td", [_vm._v("JsonObject")]),
                _vm._v(" "),
                _c("td", [_vm._v("返回结果:设备最新上报数据的键值对")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("4.3  设备历史数据查询")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.3.1 业务功能")]),
          _vm._v(" "),
          _c("p", [_vm._v("根据传递的参数，获取满足指定条件的设备上报数据")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.3.2 接口地址")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "请求方式：post\nContent-Type：application/json\n地址：{host} /api/iotdata/query"
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.3.3 接口参数")]),
          _vm._v(" "),
          _c("pre", { pre: true }, [
            _c("code", { pre: true, attrs: { "v-pre": "" } }, [
              _vm._v("   请求参数body：\n")
            ])
          ]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("是否必填")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("startTime")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("查询开始时间，格式为yyyy-MM-dd HH:mm:ss")]),
                _vm._v(" "),
                _c("td", [_vm._v("否")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("endTime")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("查询结束时间，格式为yyyy-MM-dd HH:mm:ss")]),
                _vm._v(" "),
                _c("td", [_vm._v("否")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("deviceCode")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("需要查询的设备编码")]),
                _vm._v(" "),
                _c("td", [_vm._v("是")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("type")]),
                _vm._v(" "),
                _c("td", [_vm._v("int")]),
                _vm._v(" "),
                _c("td", [_vm._v("查询类型,1:查询属性 2:查询动作 3:查询服务")]),
                _vm._v(" "),
                _c("td")
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("默认值为1")]),
                _vm._v(" "),
                _c("td", [_vm._v("否")]),
                _vm._v(" "),
                _c("td"),
                _vm._v(" "),
                _c("td")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.3.4 返回参数")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("code")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("状态码 200为成功")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("data")]),
                _vm._v(" "),
                _c("td", [_vm._v("List")]),
                _vm._v(" "),
                _c("td", [_vm._v("对应条件设备数据键值对集合")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("message")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("结果消息")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("success")]),
                _vm._v(" "),
                _c("td", [_vm._v("boolean")]),
                _vm._v(" "),
                _c("td", [_vm._v("是否成功")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("4.4  设备新增")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.4.1 业务功能")]),
          _vm._v(" "),
          _c("p", [_vm._v("在当前产品下新增一个设备")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.4.2 接口地址")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "请求方式：POST\nContent-Type：application/json\n地址：{host} /api/device/save"
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.4.3 接口参数")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("是否必填")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("code")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("32位以内的英文与数字组合，需当前产品下唯一")
                ]),
                _vm._v(" "),
                _c("td", [_vm._v("是")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("remark")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备描述")]),
                _vm._v(" "),
                _c("td", [_vm._v("否")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("address")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备安装地址文本信息")]),
                _vm._v(" "),
                _c("td", [_vm._v("否")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("longitue")]),
                _vm._v(" "),
                _c("td", [_vm._v("Double")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备安装地址经度，小数保留六位")]),
                _vm._v(" "),
                _c("td", [_vm._v("否")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("latitude")]),
                _vm._v(" "),
                _c("td", [_vm._v("Double")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备安装地址纬度，小数保留六位")]),
                _vm._v(" "),
                _c("td", [_vm._v("否")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.4.4 返回参数")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("code")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("状态码 200为成功")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Message")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("结果消息")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Success")]),
                _vm._v(" "),
                _c("td", [_vm._v("boolean")]),
                _vm._v(" "),
                _c("td", [_vm._v("是否成功")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("4.5  更新设备信息")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.5.1 业务功能")]),
          _vm._v(" "),
          _c("p", [_vm._v("更新设备一些基础信息")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.5.2 接口地址")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "请求方式：POST\nContent-Type：application/json\n地址：{host} /api/device/update"
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.5.3 接口参数")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("是否必填")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("id")]),
                _vm._v(" "),
                _c("td", [_vm._v("Long")]),
                _vm._v(" "),
                _c("td", [_vm._v("需要更新的设备id")]),
                _vm._v(" "),
                _c("td", [_vm._v("是")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("remark")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备描述")]),
                _vm._v(" "),
                _c("td", [_vm._v("否")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("address")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备安装地址文本信息")]),
                _vm._v(" "),
                _c("td", [_vm._v("否")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("longitue")]),
                _vm._v(" "),
                _c("td", [_vm._v("Double")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备安装地址经度，小数保留六位")]),
                _vm._v(" "),
                _c("td", [_vm._v("否")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("latitude")]),
                _vm._v(" "),
                _c("td", [_vm._v("Double")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备安装地址纬度，小数保留六位")]),
                _vm._v(" "),
                _c("td", [_vm._v("否")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.5.4 返回参数")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("code")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("状态码 200为成功")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Message")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("结果消息")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Success")]),
                _vm._v(" "),
                _c("td", [_vm._v("boolean")]),
                _vm._v(" "),
                _c("td", [_vm._v("是否成功")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("4.6  删除设备")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.6.1 业务功能")]),
          _vm._v(" "),
          _c("p", [_vm._v("根据设备id对设备进行删除，请谨慎操作，不可撤销")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.6.2 接口地址")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("请求方式：POST\n地址：{host} /api/device/remove/{id}")
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.6.3 接口参数")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("是否必填")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("id")]),
                _vm._v(" "),
                _c("td", [_vm._v("Long")]),
                _vm._v(" "),
                _c("td", [_vm._v("需要删除的设备id")]),
                _vm._v(" "),
                _c("td", [_vm._v("是")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.6.4 返回参数")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("code")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("状态码 200为成功")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("data")]),
                _vm._v(" "),
                _c("td", [_vm._v("Object")]),
                _vm._v(" "),
                _c("td")
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("message")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("结果消息")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("success")]),
                _vm._v(" "),
                _c("td", [_vm._v("boolean")]),
                _vm._v(" "),
                _c("td", [_vm._v("是否成功")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("4.7 获取设备详情")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.7.1 业务功能")]),
          _vm._v(" "),
          _c("p", [_vm._v("根据设备id获取设备详情")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.7.2 接口地址")]),
          _vm._v(" "),
          _c("p", [_vm._v("请求方式：GET\n地址：{host} /api/device/get/{id}")]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.7.3 接口参数")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("是否必填")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("id")]),
                _vm._v(" "),
                _c("td", [_vm._v("Long")]),
                _vm._v(" "),
                _c("td", [_vm._v("需要查询的设备id")]),
                _vm._v(" "),
                _c("td", [_vm._v("是")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("4.7.4 返回参数")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("code")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("状态码 200为成功")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("data")]),
                _vm._v(" "),
                _c("td", [_vm._v("Object")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备详情实体 具体参照下表")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("message")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("结果消息")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("success")]),
                _vm._v(" "),
                _c("td", [_vm._v("boolean")]),
                _vm._v(" "),
                _c("td", [_vm._v("是否成功")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("设备实体参数(data)解释:")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_c("strong", [_vm._v("参数名")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数类型")])]),
                _vm._v(" "),
                _c("th", [_c("strong", [_vm._v("参数说明")])])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("code")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备编码")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("deviceName")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备名称")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("productName")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("所属产品名称")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("productId")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("所属产品Id")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("metaData")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("物模型数据")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("nodeType")]),
                _vm._v(" "),
                _c("td", [_vm._v("Int")]),
                _vm._v(" "),
                _c("td", [_vm._v("节点类型1:直连设备 2:网关设备 3:网关子设备")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("productCode")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("所属产品编码")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("keyInfo")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备key")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("status")]),
                _vm._v(" "),
                _c("td", [_vm._v("int")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备状态：0未激活 1在线 2离线3未知")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("source")]),
                _vm._v(" "),
                _c("td", [_vm._v("int")]),
                _vm._v(" "),
                _c("td", [_vm._v("来源：0自主创建 1自动创建")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("activationTime")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("激活时间")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("latestOnlineTime")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("最后在线时间")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("remark")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备描述")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("imei")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("物联网卡的iccid")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("address")]),
                _vm._v(" "),
                _c("td", [_vm._v("String")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备安装地址")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("longitude")]),
                _vm._v(" "),
                _c("td", [_vm._v("Double")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备安装地址经度")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("latitude")]),
                _vm._v(" "),
                _c("td", [_vm._v("Double")]),
                _vm._v(" "),
                _c("td", [_vm._v("设备安装地址纬度")])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ec644890", { render: render, staticRenderFns: staticRenderFns })
  }
}